import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import scrollToSection from 'utils/scrollToSection';

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const LinkItem = styled.li`
  position: relative;
  color: ${({ theme }) => theme.colors.green};
  font-size: 1.8rem;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.3s;
  @media (hover: hover) {
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.green};
    transition: 0.3s;
    transform: scaleX(0);
    transform-origin: 0 50%;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.m};
    margin: auto;
  }
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Links = ({ close }) => {
  const location =
    typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <>
      <List>
        <LinkItem onClick={close}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#o-inwestycji');
                return;
              }
              window.location.href = '/#o-inwestycji';
            }}
          >
            O inwestycji
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#lokalizacja');
                return;
              }
              window.location.href = '/#lokalizacja';
            }}
          >
            Lokalizacja
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#mieszkania');
                return;
              }
              window.location.href = '/#mieszkania';
            }}
          >
            Mieszkania
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#kredyt');
                return;
              }
              window.location.href = '/#kredyt';
            }}
          >
            Kredyt
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#wykonczenia');
                return;
              }
              window.location.href = '/#wykonczenia';
            }}
          >
            Wykończenia
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#galeria');
                return;
              }
              window.location.href = '/#galeria';
            }}
          >
            Galeria
          </StyledLink>
        </LinkItem>
        <LinkItem onClick={close}>
          <StyledLink
            onClick={() => {
              if (location === '/') {
                scrollToSection('#kontakt');
                return;
              }
              window.location.href = '/#kontakt';
            }}
          >
            Kontakt
          </StyledLink>
        </LinkItem>
      </List>
    </>
  );
};

Links.propTypes = {
  close: PropTypes.func,
};

Links.defaultProps = {
  close: () => null,
};

export default Links;
