import React, { useContext } from 'react';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import Logo from 'components/shared/Logo';
import menuIcon from 'assets/icons/menu.svg';
import Links from 'components/Navigation/Links';
import Sidenav from 'components/Navigation/Sidenav';
import useMedia from 'hooks/useMedia';
import prokocimLogo from 'assets/icons/logo-prokocim-2.svg';
import { NavigationContext } from 'contexts/NavigationContext';
import scrollToSection from 'utils/scrollToSection';

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.navSize.mobile};
  z-index: 100;
  transform: ${({ navHidden, theme }) =>
    navHidden ? `translate3d(0, -${theme.navSize.mobile}, 0)` : 'none'};
  transition: transform ease-in-out 250ms;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.2);
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
    transform: ${({ navHidden, theme }) =>
      navHidden ? `translate3d(0, -${theme.navSize.desktop}, 0)` : 'none'};
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: none;
  width: 100%;
  padding: 0 10px;
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: space-evenly;
  }
`;

const StyledContent = styled(Content)`
  padding: 0 10px !important;
  ${({ theme }) => theme.mq.s} {
    padding: 0 20px !important;
  }
`;

const MenuWrapper = styled.button`
  display: block;
  background: transparent;
  cursor: pointer;
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

const IconWrapper = styled.a`
  display: none;
  width: 200px;
  height: auto;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;

const WishlistButton = styled.button`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    padding: 5px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);
    margin-left: 30px;
    cursor: pointer;
  }
`;

const Badge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  background: ${({ theme }) => theme.colors.green};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-size: 11px;
`;

const Navigation = () => {
  const matchesLG = useMedia('(min-width: 992px)');
  const {
    isSidenavVisible,
    closeSidenav,
    isNavHidden,
    openSidenav,
    hideNav,
    favsLength,
  } = useContext(NavigationContext);

  const handleFavClick = () => {
    if (matchesLG) {
      scrollToSection('#search-3d-anchor');

      setTimeout(() => {
        hideNav();
      }, 1000);
    }
  };

  return (
    <>
      {!matchesLG && (
        <Sidenav isVisible={isSidenavVisible} close={closeSidenav} />
      )}
      <Wrapper navHidden={isNavHidden}>
        <StyledContent>
          <InnerWrapper>
            <Logo />
            {!matchesLG && (
              <MenuWrapper onClick={openSidenav}>
                <Icon size={40} src={menuIcon} />
              </MenuWrapper>
            )}
            <LinksWrapper>
              <Links close={() => setTimeout(hideNav, 800)} />
            </LinksWrapper>
            <IconWrapper href="/">
              <Icon src={prokocimLogo} alt="prokocim logo" full />
            </IconWrapper>
            <WishlistButton onClick={handleFavClick} title="Schowek">
              <Badge>{favsLength}</Badge>
              <svg
                enableBackground="new 0 0 24 24"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#f44336"
                  d="m11.466 22.776c.141.144.333.224.534.224s.393-.08.534-.224l9.594-9.721c4.001-4.053 1.158-11.055-4.532-11.055-3.417 0-4.985 2.511-5.596 2.98-.614-.471-2.172-2.98-5.596-2.98-5.672 0-8.55 6.984-4.531 11.055z"
                />
              </svg>
            </WishlistButton>
          </InnerWrapper>
        </StyledContent>
      </Wrapper>
    </>
  );
};

export default Navigation;
