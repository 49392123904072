import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import GlobalStyle from 'theme/GlobalStyle';
import { theme as mainTheme } from 'theme/mainTheme';
import NavigationProvider from 'contexts/NavigationContext';
import FavouritesProvider from 'contexts/FavouritesContext';
import Navigation from 'components/Navigation/Navigation';
import 'react-medium-image-zoom/dist/styles.css';
import { saveVistorId } from 'utils/emitMatomoEvent';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const MainLayout = ({ children }) => {
  useEffect(() => {
    saveVistorId();
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <NavigationProvider>
        <FavouritesProvider>
          <Wrapper id="home">
            <Navigation />
            {children}
          </Wrapper>
        </FavouritesProvider>
      </NavigationProvider>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainLayout;
